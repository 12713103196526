import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import { t } from 'localization';

import SwitchToggle from '@just-ai/just-ui/dist/SwitchToggle';
import ControlledSelect from 'components/ControlledSelect';
import ResizableTextArea from 'components/ResizableTextArea';
import IconSelect from 'components/IconSelect';

import { useTrainingFormContext } from '../..';
import { useTrainingContext } from 'modules/ServiceFitComponents/contexts/TrainingContext';
import { ConfigDefinitionMode, TrainingFormFieldsNames } from '../../types';

import styles from './styles.module.scss';

const ConfigurationEdit = () => {
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useTrainingFormContext();
  const { fitConfigs } = useTrainingContext();

  const watchShouldEditConfig = watch(TrainingFormFieldsNames.shouldEditConfig);
  const watchConfigMode = watch(TrainingFormFieldsNames.configMode);

  const changeConfigMode = useCallback(
    (newMode: ConfigDefinitionMode) => {
      setValue(TrainingFormFieldsNames.configMode, newMode);
    },
    [setValue]
  );

  const fitConfigList = useMemo(
    () => fitConfigs?.map(config => ({ label: config.name, value: config.id.configId.toString() })),
    [fitConfigs]
  );

  const configModeSelectOptions = [
    { label: 'faChevronDown', value: ConfigDefinitionMode.selectExistingConfig },
    { label: 'farEdit', value: ConfigDefinitionMode.writeConfig },
  ];
  const configErrors =
    watchConfigMode === ConfigDefinitionMode.selectExistingConfig
      ? errors[TrainingFormFieldsNames.configId]
      : errors[TrainingFormFieldsNames.config];

  return (
    <>
      <div className={styles.configurationEdit__row}>
        <Controller
          name='shouldEditConfig'
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <div>
              <SwitchToggle {...field} id='configurationToggle' size='md' switchType='round' color='primary' />
            </div>
          )}
        />
        <span>{t('TrainingWidget:TrainingForm:FitConfigEdit')}</span>
      </div>
      <div className={cn(styles.configurationEdit__row, styles.configurationEdit__content)}>
        {watchShouldEditConfig && <input type='hidden' {...register('configMode')} />}
        <div className={styles.configurationEdit__selectWrap}>
          {watchShouldEditConfig && watchConfigMode === 'writeConfig' && (
            <div className={styles.configurationEdit__textareaContainer}>
              <Controller
                name='config'
                control={control}
                rules={{ required: false }}
                render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
                  <ResizableTextArea
                    name={name}
                    placeholder={t('TrainModelPage:Form:ConfigPlaceholder')}
                    className={styles.configurationEdit__textarea}
                    value={value}
                    onChange={onChange}
                    errorText={invalid ? error?.message : ''}
                    hasError={invalid}
                  />
                )}
              />
            </div>
          )}
          <ControlledSelect
            data-test-id='configId'
            name='configId'
            control={control}
            rules={{ required: false }}
            options={fitConfigList}
            fullWidth
            className={cn(
              styles.configurationEdit__select,
              (!watchShouldEditConfig || watchConfigMode === 'writeConfig') && styles.configurationEdit__hidden
            )}
            inputPlaceholder={t('TrainModelPage:Form:SelectConfigPlaceholder')}
          />
        </div>
        {watchShouldEditConfig && (
          <IconSelect
            value={watchConfigMode}
            className={styles['configurationEdit__button_edit']}
            options={configModeSelectOptions}
            onChange={val => {
              changeConfigMode(val as ConfigDefinitionMode);
            }}
          />
        )}
        {!watchShouldEditConfig && !!configErrors?.message && (
          <span className={styles.configurationEdit__error}>{configErrors.message}</span>
        )}
      </div>
    </>
  );
};

export default React.memo(ConfigurationEdit);
