import React from 'react';
import Input, { InputInterface } from 'components/Input';
import { useController, Control } from 'react-hook-form';

interface ControlledInputInterface extends Partial<InputInterface> {
  name: string;
  title?: string;
  placeholder?: string;
  defaultValue?: string;
  control: Control<any>;
  onKeyUp?: (e: KeyboardEvent, value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const ControlledInput = ({
  name,
  control,
  defaultValue,
  title,
  placeholder,
  onKeyUp,
  onBlur,
  onFocus,
  type = 'text',
  ...props
}: ControlledInputInterface) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({ name, control, defaultValue: defaultValue || '' });
  return (
    <Input
      {...props}
      {...field}
      innerRef={field.ref}
      title={title}
      errorText={invalid ? error?.message : ''}
      hasError={invalid}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus}
      type={type}
    />
  );
};

export default React.memo(ControlledInput) as typeof ControlledInput;
