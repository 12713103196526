import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Container from 'typedi';

import { MlpTokenPackages, RefillFromCardResponse, ShortBalance } from 'api/CailaBillingApi/api/client';
import Spinner from 'components/Spinner';
import { useBalanceRefillModal } from './components/BalanceRefillModal';
import UserInfoBanner from './components/UserInfoBanner';
import { tokens } from 'diConfig';
import { useUserInfoBalance } from './hooks';
import { useInterval } from 'utils/hooks';
import { useAuthContext } from 'contexts/AuthContext';
import { FEATURES } from 'contexts/AuthContext/constants';

const LONG_POLLING_INTERVAL = 5000;

export type BalanceContextType = {
  balanceInfo?: ShortBalance;
  getBalance: () => Promise<void>;
  refillFromCard: (packageId: string, redirectUrl: string) => Promise<RefillFromCardResponse | undefined>;
  refillFromIncomeBalance: (amountRub: number) => Promise<void>;
  availableRefillPackages?: MlpTokenPackages;
  openBalanceRefillModal: () => void;
  checkIfUserIsBlocked: () => Promise<void>;
  isBillingEnabled: boolean;
};

export const BalanceContext = createContext({} as BalanceContextType);

interface BalanceContextProviderProps {}

export const BalanceContextProviderComponent: FunctionComponent<BalanceContextProviderProps> = ({ children }) => {
  const {
    checkIfUserIsBlocked,
    getAvailableRefillPackages,
    getBalance,
    refillFromCard,
    refillFromIncomeBalance,
    state: { isUserBlocked, availableRefillPackages, balanceInfo, isBillingEnabled },
    isLoading,
  } = useUserInfoBalance();
  const { userHasFeature } = useAuthContext();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    Promise.allSettled([checkIfUserIsBlocked(), getAvailableRefillPackages(), getBalance()]).finally(() => {
      setIsInitialized(true);
    });
  }, [checkIfUserIsBlocked, getAvailableRefillPackages, getBalance, isBillingEnabled, setIsInitialized]);

  useInterval(getBalance, LONG_POLLING_INTERVAL, false);

  const [BalanceRefillModalNode, openBalanceRefillModal] = useBalanceRefillModal();

  const handleOpenBalanceRefillModal = useCallback(() => {
    isBillingEnabled && openBalanceRefillModal();
  }, [isBillingEnabled, openBalanceRefillModal]);

  const showDangerBanner = !!isUserBlocked;
  const showWarningBanner =
    !showDangerBanner &&
    !userHasFeature(FEATURES.MLP_BILLING_NO_BLOCKING) &&
    !!(balanceInfo?.overallBalanceRub !== undefined && balanceInfo.overallBalanceRub < 0);

  const placeToMountBanner = document.getElementById(Container.get(tokens.BANNER_MOUNT_ID));

  if (!isInitialized) return <Spinner show />;

  return (
    <BalanceContext.Provider
      value={{
        balanceInfo,
        getBalance,
        refillFromCard,
        refillFromIncomeBalance,
        availableRefillPackages,
        openBalanceRefillModal: handleOpenBalanceRefillModal,
        checkIfUserIsBlocked,
        isBillingEnabled,
      }}
    >
      <Spinner show={isLoading} />
      {children}
      {isBillingEnabled && (
        <>
          {placeToMountBanner && (
            <>
              {showDangerBanner && ReactDOM.createPortal(<UserInfoBanner type='danger' />, placeToMountBanner)}
              {showWarningBanner && ReactDOM.createPortal(<UserInfoBanner type='warning' />, placeToMountBanner)}
            </>
          )}
          {BalanceRefillModalNode}
        </>
      )}
    </BalanceContext.Provider>
  );
};

export const useBalanceContext = () => useContext(BalanceContext);
