import React from 'react';
import { StatusType, StatusIndicatorMap } from './types';
import cn from 'classnames';
import styles from './styles.module.scss';

interface StatusIndicatorInterface {
  status?: StatusType;
  label?: string;
  dataTestId?: string;
  colorizeLabel?: boolean;
  className?: string;
}

const StatusIndicator = ({ status, label, dataTestId, className, colorizeLabel = false }: StatusIndicatorInterface) => {
  if (!status) return null;
  const style = StatusIndicatorMap[status];
  if (label)
    return (
      <div data-test-id={dataTestId} className={cn(styles.status__container, className)}>
        <span className={cn(styles.status__indicator, style)} />
        <span className={cn(styles.status__label, colorizeLabel && style)}>{label}</span>
      </div>
    );
  return <span className={cn(styles.status__indicator, style, className)} />;
};

export default React.memo(StatusIndicator);
