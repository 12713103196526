import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '@just-ai/just-ui/dist/Dropdown';
import HeaderButton from '../HeaderButton';

import { useToggle } from 'utils/hooks';
import { useAuthContext } from 'contexts/AuthContext';
import { PERMISSIONS } from 'contexts/AuthContext/constants';
import { WORKSPACE_BASE_PATH } from 'routes';
import { t } from 'localization';
import { useAppContext } from 'contexts/AppContext';

interface AccountMenuProps {
  userName: string;
  logout: () => void;
}

const AccountMenu = function ({ userName, logout }: AccountMenuProps) {
  const [open, , , toggle] = useToggle(false);
  const { userHasPermission } = useAuthContext();
  const { isBillingEnabled } = useAppContext();
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle tag='div'>
        <HeaderButton
          className='user'
          data-test-id='headerMenuButton'
          color='primary'
          flat
          iconRight='farUser'
        ></HeaderButton>
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu__account'>
        {userHasPermission(PERMISSIONS.MLP_ADMIN) && (
          <Link to={`/${WORKSPACE_BASE_PATH}/account-info`}>
            <DropdownItem className='dropdown-item account-btn' data-test-id='headerMenuAccountLink' iconRight='faUser'>
              {t('Header:AccountMenu:Account')}
            </DropdownItem>
          </Link>
        )}
        {isBillingEnabled && (
          <Link to={`/${WORKSPACE_BASE_PATH}/balance-info`}>
            <DropdownItem
              className='dropdown-item account-btn'
              data-test-id='headerMenuBalanceLink'
              iconRight='faCoins'
            >
              {t('Header:AccountMenu:Balance')}
            </DropdownItem>
          </Link>
        )}
        <DropdownItem
          className='dropdown-item account-btn'
          data-test-id='headerMenuLogout'
          onClick={() => logout()}
          iconRight='faSignOut'
        >
          {t('Header:AccountMenu:Logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(AccountMenu);
