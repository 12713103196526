import React, { Ref, Suspense } from 'react';
import remarkGfm from 'remark-gfm';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import { Spinner } from 'components/Spinner';

import './style.scss';

const ReactMarkdown = React.lazy(() => import('react-markdown'));

interface MarkdownContainerInterface {
  children: string;
  className?: string;
}

const MarkdownContainer = function ({ children, className }: MarkdownContainerInterface, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className={className}>
      <Suspense fallback={<Spinner />}>
        <ReactMarkdown
          children={children}
          className='markdown-container'
          remarkPlugins={[remarkGfm]}
          linkTarget='_blank'
          components={{
            a: ({ href, children }) => (
              <a href={href} className='markdown-container__link'>
                <span>{children}</span>
                <Icon name='faExternalLink' />
              </a>
            ),
          }}
        />
      </Suspense>
    </div>
  );
};

export default React.memo(React.forwardRef(MarkdownContainer));
