// tslint:disable
// @ts-nocheck
/**
 * MLP Billing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudTariffSpending?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudTariffQuota?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageSpending?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageSpendingFromPeriodStart?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageQuota?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudNegativeBalanceTimestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    hardwareBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    incomeBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    lastSpending?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    lastUpdateFromAA?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    lastResetUpdateFromAA?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Balance
     */
    cloudBlocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Balance
     */
    hostingBlocked?: boolean;
}
/**
 * 
 * @export
 * @interface BalanceHistory
 */
export interface BalanceHistory {
    /**
     * 
     * @type {number}
     * @memberof BalanceHistory
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceHistory
     */
    periodType: PeriodTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BalanceHistory
     */
    periodStart: Date;
    /**
     * 
     * @type {Array<SpendingByTime>}
     * @memberof BalanceHistory
     */
    spendingByTime: Array<SpendingByTime>;
    /**
     * 
     * @type {Array<SpendingByModel>}
     * @memberof BalanceHistory
     */
    spendingByModel: Array<SpendingByModel>;
}

/**
    * @export
    * @enum {string}
    */
export enum PeriodTypeEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface IncomeDetailsDto
 */
export interface IncomeDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailsDto
     */
    interval: IntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailsDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    calls: number;
    /**
     * 
     * @type {Array<IncomeHistoryItem>}
     * @memberof IncomeDetailsDto
     */
    incomes: Array<IncomeHistoryItem>;
}

/**
    * @export
    * @enum {string}
    */
export enum IntervalEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface IncomeHistoryItem
 */
export interface IncomeHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof IncomeHistoryItem
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    calls: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    units: number;
}
/**
 * 
 * @export
 * @interface LimitsAndBalance
 */
export interface LimitsAndBalance {
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensPackageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedPackageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedPackageLimitWhenTariffPeriodStarted: number;
}
/**
 * 
 * @export
 * @interface LimitsAndBalances
 */
export interface LimitsAndBalances {
    /**
     * 
     * @type {Array<LimitsAndBalance>}
     * @memberof LimitsAndBalances
     */
    limits: Array<LimitsAndBalance>;
}
/**
 * 
 * @export
 * @interface LimitsRequest
 */
export interface LimitsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof LimitsRequest
     */
    accountIds: Array<number>;
}
/**
 * 
 * @export
 * @interface MlpTokenPackage
 */
export interface MlpTokenPackage {
    /**
     * 
     * @type {string}
     * @memberof MlpTokenPackage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MlpTokenPackage
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof MlpTokenPackage
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof MlpTokenPackage
     */
    rubles: number;
}
/**
 * 
 * @export
 * @interface MlpTokenPackages
 */
export interface MlpTokenPackages {
    /**
     * 
     * @type {Array<MlpTokenPackage>}
     * @memberof MlpTokenPackages
     */
    packages: Array<MlpTokenPackage>;
}
/**
 * 
 * @export
 * @interface RefillFromCardRequest
 */
export interface RefillFromCardRequest {
    /**
     * 
     * @type {number}
     * @memberof RefillFromCardRequest
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof RefillFromCardRequest
     */
    packageId: string;
    /**
     * 
     * @type {string}
     * @memberof RefillFromCardRequest
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface RefillFromCardResponse
 */
export interface RefillFromCardResponse {
    /**
     * 
     * @type {string}
     * @memberof RefillFromCardResponse
     */
    paymentUrl: string;
}
/**
 * 
 * @export
 * @interface RefillFromIncomeRequest
 */
export interface RefillFromIncomeRequest {
    /**
     * 
     * @type {number}
     * @memberof RefillFromIncomeRequest
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof RefillFromIncomeRequest
     */
    amountRub: number;
}
/**
 * 
 * @export
 * @interface ShortBalance
 */
export interface ShortBalance {
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    overallBalanceRub?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    jaicpTariffBalanceRub?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    incomeBalanceRub?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    nextRefillRub?: number;
    /**
     * 
     * @type {Date}
     * @memberof ShortBalance
     */
    nextRefillDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ShortBalance
     */
    isBlocked: boolean;
}
/**
 * 
 * @export
 * @interface SpendRequest
 */
export interface SpendRequest {
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    apiToken: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    spendingAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    spendingAccountName: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    spendingModelId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    spendingModelName: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    units: number;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    nanoTokens: number;
}
/**
 * 
 * @export
 * @interface SpendingByModel
 */
export interface SpendingByModel {
    /**
     * 
     * @type {number}
     * @memberof SpendingByModel
     */
    spendingAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingByModel
     */
    spendingModelId: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingByModel
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface SpendingByTime
 */
export interface SpendingByTime {
    /**
     * 
     * @type {Date}
     * @memberof SpendingByTime
     */
    timestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof SpendingByTime
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface SpendingDetailsDto
 */
export interface SpendingDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof SpendingDetailsDto
     */
    interval: IntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof SpendingDetailsDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    calls: number;
    /**
     * 
     * @type {Array<SpendingHistoryPerApiToken>}
     * @memberof SpendingDetailsDto
     */
    spendings: Array<SpendingHistoryPerApiToken>;
}

/**
    * @export
    * @enum {string}
    */
export enum IntervalEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface SpendingHistoryPerApiToken
 */
export interface SpendingHistoryPerApiToken {
    /**
     * 
     * @type {string}
     * @memberof SpendingHistoryPerApiToken
     */
    apiToken: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingHistoryPerApiToken
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingHistoryPerApiToken
     */
    calls: number;
    /**
     * 
     * @type {Array<SpendingItem>}
     * @memberof SpendingHistoryPerApiToken
     */
    services: Array<SpendingItem>;
}
/**
 * 
 * @export
 * @interface SpendingItem
 */
export interface SpendingItem {
    /**
     * 
     * @type {string}
     * @memberof SpendingItem
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof SpendingItem
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    calls: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    units: number;
}
/**
 * 
 * @export
 * @interface UpdateLimitsRequest
 */
export interface UpdateLimitsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    mlpTokensTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    mlpTokensPackageLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLimitsRequest
     */
    reset: boolean;
}

/**
 * AaCallbackEndpointApi - axios parameter creator
 * @export
 */
export const AaCallbackEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'limitsRequest' is not null or undefined
            if (limitsRequest === null || limitsRequest === undefined) {
                throw new RequiredError('limitsRequest','Required parameter limitsRequest was null or undefined when calling getMultipleLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof limitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(limitsRequest !== undefined ? limitsRequest : {}) : (limitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'updateLimitsRequest' is not null or undefined
            if (updateLimitsRequest === null || updateLimitsRequest === undefined) {
                throw new RequiredError('updateLimitsRequest','Required parameter updateLimitsRequest was null or undefined when calling setLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLimitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLimitsRequest !== undefined ? updateLimitsRequest : {}) : (updateLimitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AaCallbackEndpointApi - functional programming interface
 * @export
 */
export const AaCallbackEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalance> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).getLimits(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalances> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).getMultipleLimits(limitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).setLimits(updateLimitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AaCallbackEndpointApi - factory interface
 * @export
 */
export const AaCallbackEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options?: any): AxiosPromise<LimitsAndBalance> {
            return AaCallbackEndpointApiFp(configuration).getLimits(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options?: any): AxiosPromise<LimitsAndBalances> {
            return AaCallbackEndpointApiFp(configuration).getMultipleLimits(limitsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any): AxiosPromise<void> {
            return AaCallbackEndpointApiFp(configuration).setLimits(updateLimitsRequest, options)(axios, basePath);
        },
    };
};

/**
 * AaCallbackEndpointApi - object-oriented interface
 * @export
 * @class AaCallbackEndpointApi
 * @extends {BaseAPI}
 */
export class AaCallbackEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public getLimits(accountId: number, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).getLimits(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LimitsRequest} limitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public getMultipleLimits(limitsRequest: LimitsRequest, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).getMultipleLimits(limitsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateLimitsRequest} updateLimitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).setLimits(updateLimitsRequest, options)(this.axios, this.basePath);
    }

}


/**
 * BalanceInfoEndpointApi - axios parameter creator
 * @export
 */
export const BalanceInfoEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling checkBlocked.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/blocked`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/balance`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getBalanceHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getBalanceHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/balance/history`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getIncomeHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getIncomeHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getIncomeHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/income`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getShortBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/balance/short`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getSpendingHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getSpendingHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getSpendingHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/spending`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceInfoEndpointApi - functional programming interface
 * @export
 */
export const BalanceInfoEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).checkBlocked(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getBalance(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceHistory> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getBalanceHistory(accountId, period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeDetailsDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getIncomeHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortBalance> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getShortBalance(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpendingDetailsDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getSpendingHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BalanceInfoEndpointApi - factory interface
 * @export
 */
export const BalanceInfoEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options?: any): AxiosPromise<boolean> {
            return BalanceInfoEndpointApiFp(configuration).checkBlocked(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options?: any): AxiosPromise<Balance> {
            return BalanceInfoEndpointApiFp(configuration).getBalance(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', options?: any): AxiosPromise<BalanceHistory> {
            return BalanceInfoEndpointApiFp(configuration).getBalanceHistory(accountId, period, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<IncomeDetailsDto> {
            return BalanceInfoEndpointApiFp(configuration).getIncomeHistory(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options?: any): AxiosPromise<ShortBalance> {
            return BalanceInfoEndpointApiFp(configuration).getShortBalance(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<SpendingDetailsDto> {
            return BalanceInfoEndpointApiFp(configuration).getSpendingHistory(accountId, period, date, options)(axios, basePath);
        },
    };
};

/**
 * BalanceInfoEndpointApi - object-oriented interface
 * @export
 * @class BalanceInfoEndpointApi
 * @extends {BaseAPI}
 */
export class BalanceInfoEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public checkBlocked(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).checkBlocked(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getBalance(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getBalance(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getBalanceHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getBalanceHistory(accountId, period, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getIncomeHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getShortBalance(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getShortBalance(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getSpendingHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

}


/**
 * InternalEndpointApi - axios parameter creator
 * @export
 */
export const InternalEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling-internal/run-check-blocked`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'updateLimitsRequest' is not null or undefined
            if (updateLimitsRequest === null || updateLimitsRequest === undefined) {
                throw new RequiredError('updateLimitsRequest','Required parameter updateLimitsRequest was null or undefined when calling setLimits1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account-limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLimitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLimitsRequest !== undefined ? updateLimitsRequest : {}) : (updateLimitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalEndpointApi - functional programming interface
 * @export
 */
export const InternalEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).runCheckBlocked(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).setLimits1(updateLimitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternalEndpointApi - factory interface
 * @export
 */
export const InternalEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).runCheckBlocked(options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).setLimits1(updateLimitsRequest, options)(axios, basePath);
        },
    };
};

/**
 * InternalEndpointApi - object-oriented interface
 * @export
 * @class InternalEndpointApi
 * @extends {BaseAPI}
 */
export class InternalEndpointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public runCheckBlocked(options?: any) {
        return InternalEndpointApiFp(this.configuration).runCheckBlocked(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateLimitsRequest} updateLimitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any) {
        return InternalEndpointApiFp(this.configuration).setLimits1(updateLimitsRequest, options)(this.axios, this.basePath);
    }

}


/**
 * RefillEndpointApi - axios parameter creator
 * @export
 */
export const RefillEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRefillPackages(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getAvailableRefillPackages.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/refill/packages`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromCardRequest} refillFromCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCard(accountId: number, refillFromCardRequest: RefillFromCardRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling refillFromCard.');
            }
            // verify required parameter 'refillFromCardRequest' is not null or undefined
            if (refillFromCardRequest === null || refillFromCardRequest === undefined) {
                throw new RequiredError('refillFromCardRequest','Required parameter refillFromCardRequest was null or undefined when calling refillFromCard.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/refill/card`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof refillFromCardRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refillFromCardRequest !== undefined ? refillFromCardRequest : {}) : (refillFromCardRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling refillFromIncomeBalance.');
            }
            // verify required parameter 'refillFromIncomeRequest' is not null or undefined
            if (refillFromIncomeRequest === null || refillFromIncomeRequest === undefined) {
                throw new RequiredError('refillFromIncomeRequest','Required parameter refillFromIncomeRequest was null or undefined when calling refillFromIncomeBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/refill/income`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof refillFromIncomeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refillFromIncomeRequest !== undefined ? refillFromIncomeRequest : {}) : (refillFromIncomeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefillEndpointApi - functional programming interface
 * @export
 */
export const RefillEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRefillPackages(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlpTokenPackages> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).getAvailableRefillPackages(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromCardRequest} refillFromCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCard(accountId: number, refillFromCardRequest: RefillFromCardRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillFromCardResponse> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).refillFromCard(accountId, refillFromCardRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).refillFromIncomeBalance(accountId, refillFromIncomeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RefillEndpointApi - factory interface
 * @export
 */
export const RefillEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRefillPackages(accountId: number, options?: any): AxiosPromise<MlpTokenPackages> {
            return RefillEndpointApiFp(configuration).getAvailableRefillPackages(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromCardRequest} refillFromCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCard(accountId: number, refillFromCardRequest: RefillFromCardRequest, options?: any): AxiosPromise<RefillFromCardResponse> {
            return RefillEndpointApiFp(configuration).refillFromCard(accountId, refillFromCardRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any): AxiosPromise<void> {
            return RefillEndpointApiFp(configuration).refillFromIncomeBalance(accountId, refillFromIncomeRequest, options)(axios, basePath);
        },
    };
};

/**
 * RefillEndpointApi - object-oriented interface
 * @export
 * @class RefillEndpointApi
 * @extends {BaseAPI}
 */
export class RefillEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public getAvailableRefillPackages(accountId: number, options?: any) {
        return RefillEndpointApiFp(this.configuration).getAvailableRefillPackages(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {RefillFromCardRequest} refillFromCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public refillFromCard(accountId: number, refillFromCardRequest: RefillFromCardRequest, options?: any) {
        return RefillEndpointApiFp(this.configuration).refillFromCard(accountId, refillFromCardRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public refillFromIncomeBalance(accountId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any) {
        return RefillEndpointApiFp(this.configuration).refillFromIncomeBalance(accountId, refillFromIncomeRequest, options)(this.axios, this.basePath);
    }

}


/**
 * SpendingEndpointApi - axios parameter creator
 * @export
 */
export const SpendingEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling spend.');
            }
            // verify required parameter 'spendRequest' is not null or undefined
            if (spendRequest === null || spendRequest === undefined) {
                throw new RequiredError('spendRequest','Required parameter spendRequest was null or undefined when calling spend.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/spend`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof spendRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spendRequest !== undefined ? spendRequest : {}) : (spendRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpendingEndpointApi - functional programming interface
 * @export
 */
export const SpendingEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spend(accountId, spendRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpendingEndpointApi - factory interface
 * @export
 */
export const SpendingEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spend(accountId, spendRequest, options)(axios, basePath);
        },
    };
};

/**
 * SpendingEndpointApi - object-oriented interface
 * @export
 * @class SpendingEndpointApi
 * @extends {BaseAPI}
 */
export class SpendingEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {SpendRequest} spendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spend(accountId: number, spendRequest: SpendRequest, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spend(accountId, spendRequest, options)(this.axios, this.basePath);
    }

}


/**
 * SystemEndpointApi - axios parameter creator
 * @export
 */
export const SystemEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemEndpointApi - functional programming interface
 * @export
 */
export const SystemEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).health(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).version(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemEndpointApi - factory interface
 * @export
 */
export const SystemEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<string> {
            return SystemEndpointApiFp(configuration).health(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): AxiosPromise<string> {
            return SystemEndpointApiFp(configuration).version(options)(axios, basePath);
        },
    };
};

/**
 * SystemEndpointApi - object-oriented interface
 * @export
 * @class SystemEndpointApi
 * @extends {BaseAPI}
 */
export class SystemEndpointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public health(options?: any) {
        return SystemEndpointApiFp(this.configuration).health(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public version(options?: any) {
        return SystemEndpointApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }

}


