import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import MarkdownContainer from 'components/MarkdownContainer';
import Spinner from 'components/Spinner';

import { useAppContext } from 'contexts/AppContext';
import { useLoading } from 'utils/hooks';
import { ModelApiService } from 'services/ApiServices/ModelApiService';

const DescriptionTab = () => {
  const modelApi = useAppContext().diContainer.get(ModelApiService);
  const [isLoading, , startLoading, endLoading] = useLoading();
  const { account, service } = useParams<{ service: string; account: string }>();
  const [documentation, setDocumentation] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    startLoading();
    modelApi
      .getSimpleDoc(account, service)
      .then(response => {
        setDocumentation(response.data);
      })
      .finally(() => {
        setInitialized(true);
        endLoading();
      });
  }, [modelApi, startLoading, endLoading, account, service]);

  if (!initialized) return <Spinner show={isLoading} />;
  return (
    <>
      <Spinner show={isLoading} />
      <MarkdownContainer children={documentation} />
    </>
  );
};

export default React.memo(DescriptionTab);
