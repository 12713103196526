import { useEffect } from 'react';
import { UseFormResetField } from 'react-hook-form';

import { useTrainingContext } from 'modules/ServiceFitComponents/contexts/TrainingContext';
import { ConfigDefinitionMode, TrainingFormFieldsNames, TrainingFormFieldsType } from '../types';

export const useFormExternalData = (resetField: UseFormResetField<TrainingFormFieldsType>, deps: any[] = []) => {
  const { datasets, datasetDataTypes, fitConfigs } = useTrainingContext();
  useEffect(() => {
    let fieldsToReset: Partial<TrainingFormFieldsType> = {};
    if (datasets?.length) {
      let datasetIdToSet = datasets[0].id?.datasetId.toString();
      fieldsToReset.datasetId = datasetIdToSet;
    }
    if (datasetDataTypes?.length) {
      let datasetDataTypeToSet = datasetDataTypes[0].toString();
      fieldsToReset.datasetDataType = datasetDataTypeToSet;
    }

    let manualFitConfigData = fitConfigs?.find(config => config.isManual);
    let defaultFitConfigId = fitConfigs?.find(config => config.isDefault)?.id?.configId.toString();

    if (manualFitConfigData) {
      const { config, id } = manualFitConfigData;
      resetField(TrainingFormFieldsNames.config, { defaultValue: config });
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.configId, { defaultValue: id.configId.toString() });
        resetField(TrainingFormFieldsNames.shouldEditConfig, { defaultValue: true });
        resetField(TrainingFormFieldsNames.configMode, { defaultValue: ConfigDefinitionMode.writeConfig });
      });
      fieldsToReset.config = manualFitConfigData.config;
    } else if (defaultFitConfigId) {
      resetField(TrainingFormFieldsNames.shouldEditConfig, { defaultValue: false });
      resetField(TrainingFormFieldsNames.configMode, { defaultValue: ConfigDefinitionMode.selectExistingConfig });
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.configId, { defaultValue: fitConfigs?.[0]?.id.configId.toString() });
      });
    } else if (fitConfigs?.length) {
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.configId, { defaultValue: fitConfigs?.[0]?.id.configId.toString() });
        resetField(TrainingFormFieldsNames.configMode, { defaultValue: ConfigDefinitionMode.selectExistingConfig });
        resetField(TrainingFormFieldsNames.shouldEditConfig, { defaultValue: false });
      });
    } else {
      window.requestAnimationFrame(() => {
        resetField(TrainingFormFieldsNames.shouldEditConfig, { defaultValue: true });
        resetField(TrainingFormFieldsNames.configMode, { defaultValue: ConfigDefinitionMode.writeConfig });
      });
    }
    Object.entries(fieldsToReset).forEach(([fieldName, fieldValue]) => {
      window.requestAnimationFrame(() => {
        resetField(fieldName as keyof typeof fieldsToReset, { defaultValue: fieldValue });
      });
    });

    // eslint-disable-next-line
  }, [datasetDataTypes, datasets, fitConfigs, resetField, ...deps]);
};
