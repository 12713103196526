import React, { useCallback } from 'react';

import { Icon } from '@just-ai/just-ui/dist/Icon';
import TagsChipContainer from 'components/TagsChipContainer';
import Button from 'components/Button';

import { ModelInfoData } from 'api/CailagateApi/api/client';
import { useServicesContext } from 'contexts/ServicesContext';

import { t } from 'localization';
import styles from './styles.module.scss';
import Chip from 'components/Chip';
import { copyToClipboard } from 'utils/copyToClipboard';
import { notifyCommonMessage } from 'components/MessageContainer';

interface HeaderInterface {
  service: ModelInfoData;
  toggleFavourite: () => Promise<void>;
  showToggleFavorite?: boolean;
}
const Header = function ({ service, toggleFavourite, showToggleFavorite = true }: HeaderInterface) {
  const { getTaskTypeData } = useServicesContext();
  const taskTypeObject = getTaskTypeData(service.taskType);

  const handleToggle = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      await toggleFavourite();
    },
    [toggleFavourite]
  );
  const ownerName = service.modelAccountDisplayName || service.modelAccountName || service.id.accountId.toString();
  const onCopy = useCallback((textToCopy: string) => {
    if (!textToCopy) return;
    copyToClipboard(textToCopy).then(() => notifyCommonMessage(t('toast:TextCopiedToClipboard'), { theme: 'dark' }));
  }, []);

  return (
    <div className={styles.header}>
      <h1 data-test-id={`servicePageTitle-${service.modelName}`}>{service.modelName}</h1>
      <div className={styles.header__infoPanel}>
        <TagsChipContainer
          taskType={taskTypeObject}
          languages={service.languages}
          availableInJaicp={service.publicSettings.availableInJaicp}
        >
          <Chip
            className={styles.header__accountChip}
            text={ownerName}
            onClick={() => onCopy(service.id.accountId.toString())}
            clickable
            iconName='farUser'
          />
        </TagsChipContainer>
        {showToggleFavorite && (
          <Button className={styles.header__favoriteButton} onClick={handleToggle}>
            <Icon
              className={styles.header__favoriteIcon}
              data-test-id='ServicePage:ToggleFavorite'
              name={service.favorite ? 'faStar' : 'farStar'}
            />
            <span>{!service.favorite ? t('ServicePage:AddToFavorites') : t('ServicePage:RemoveFromFavorites')}</span>
          </Button>
        )}
      </div>
      <p className={styles.header__description}>{service.shortDescription}</p>
    </div>
  );
};

export default React.memo(Header);
