import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { history } from 'App';
import { t } from 'localization';

import CardsList from './components/CardsList';
import { CardItem } from './components/CardItem';
import Carousel from './components/Carousel';
import Spinner from 'components/Spinner';
import SearchFilter from 'components/SearchFilter';
import Paginate from 'components/Paginate';

import { useAuthContext } from 'contexts/AuthContext';
import { useDI } from 'contexts/AppContext';
import { useLoading } from 'utils/hooks';
import { useCatalogState } from './hooks';
import { useCancelableState } from 'utils/hooks/useCancelableState';

import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { AbortControllerService } from 'services/AbortControllerService';
import { ModelInfoData } from 'api/CailagateApi/api/client';

import FiltersContainer from './components/FiltersContainer';
import { FilterOptions } from './components/FiltersContainer/types';
import { AppLogger } from 'services/AppLogger';

import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';
import { ITEMS_PER_CAROUSEL_SLIDE, ITEMS_PER_PAGE, ONLY_PUBLIC_MODELS, PAGE_RANGE_TO_DISPLAY } from './constants';

import styles from './styles.module.scss';

const FAVORITE_DEBOUNCE_TIME = 300;

const CatalogListPage = () => {
  const {
    currentPage,
    filterState: { filterData, addNewFilter, deleteFilter, updateFilter, filterValues },
    hasActiveFilters,
    isInitialized,
    nameFilter,
    setCurrentPage,
    setNameFilter,
    pageCount,
    setPageCount,
  } = useCatalogState();

  const [currentItems, setCurrentItems, setCurrentItemsCancelable, cancelCurrentItems] = useCancelableState<
    ModelInfoData[] | undefined
  >();
  const [carouselItems, setCarouselItems, setCarouselItemsCancelable, cancelCarouselItems] = useCancelableState<
    ModelInfoData[] | undefined
  >();
  const [isLoading, , startLoading, endLoading] = useLoading();
  const [isCarouselLoading, , startCarouselLoading, endCarouselLoading] = useLoading();

  const modelApi = useDI(ModelApiService);
  const abortController = useDI(AbortControllerService);

  const { user } = useAuthContext();

  const handlePageChange = ({ selected: selectedPage }: { selected: number }) => {
    setCurrentPage(selectedPage);
  };

  const taskTypeFilter: string | undefined = useMemo(() => filterValues[FilterOptions.TaskType], [filterValues]);

  const getModelsList = useCallback(async () => {
    if (!isInitialized) return;
    startLoading();
    try {
      const { data: modelsPageInfo = undefined } = await abortController.get('modelsList', signal =>
        user
          ? modelApi.getPagedModels(
              user.accountId.toString(),
              undefined,
              undefined,
              undefined,
              nameFilter,
              ONLY_PUBLIC_MODELS,
              undefined,
              taskTypeFilter,
              undefined,
              currentPage,
              ITEMS_PER_PAGE,
              undefined,
              undefined,
              { signal }
            )
          : modelApi.getPublicModels(
              undefined,
              undefined,
              undefined,
              nameFilter,
              ONLY_PUBLIC_MODELS,
              undefined,
              taskTypeFilter,
              undefined,
              currentPage,
              ITEMS_PER_PAGE,
              undefined,
              undefined,
              { signal }
            )
      );
      const services = modelsPageInfo?.records;
      setCurrentItems(services);
      setPageCount(modelsPageInfo?.paging.totalPages || 0);
    } catch (error) {
      if (!axios.isCancel(error)) {
        AppLogger.error({ exception: error });
      }
    }
    endLoading();
  }, [
    abortController,
    currentPage,
    endLoading,
    isInitialized,
    modelApi,
    nameFilter,
    setCurrentItems,
    setPageCount,
    startLoading,
    taskTypeFilter,
    user,
  ]);

  const getCarouselList = useCallback(async () => {
    startCarouselLoading();
    try {
      const { data } = await modelApi.getFeaturedModels();
      setCarouselItems(data);
    } catch (error) {
      AppLogger.error({ exception: error });
    }
    endCarouselLoading();
  }, [endCarouselLoading, modelApi, setCarouselItems, startCarouselLoading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFavouriteRequest = useCallback(
    debounce(async (serviceAccountId: number, serviceId: number, favorite: boolean) => {
      await abortController.get(serviceId.toString(), signal =>
        modelApi.setFavorite(serviceAccountId.toString(), serviceId.toString(), favorite, undefined, { signal })
      );
    }, FAVORITE_DEBOUNCE_TIME),
    []
  );

  const setFavorite = useCallback(
    async (serviceAccountId: number, serviceId: number, favorite: boolean) => {
      if (!user) return;
      const updatedCurrentItems = currentItems?.map(item =>
        item.id.modelId === serviceId ? { ...item, favorite } : item
      );
      const updatedCarouselItems = carouselItems?.map(item =>
        item.id.modelId === serviceId ? { ...item, favorite } : item
      );
      setCurrentItemsCancelable(updatedCurrentItems);
      setCarouselItemsCancelable(updatedCarouselItems);
      try {
        await debouncedSetFavouriteRequest(serviceAccountId, serviceId, favorite);
        setCurrentItems(updatedCurrentItems);
        setCarouselItems(updatedCarouselItems);
      } catch (error) {
        if (!axios.isCancel(error)) {
          cancelCurrentItems();
          cancelCarouselItems();
        }
      }
    },
    [
      cancelCarouselItems,
      cancelCurrentItems,
      carouselItems,
      currentItems,
      debouncedSetFavouriteRequest,
      setCarouselItems,
      setCarouselItemsCancelable,
      setCurrentItems,
      setCurrentItemsCancelable,
      user,
    ]
  );

  useEffect(() => {
    getModelsList();
  }, [getModelsList]);

  useEffect(() => {
    getCarouselList();
  }, [getCarouselList]);

  const from = `${history.location.pathname}${history.location.search || ''}`;

  return (
    <>
      <div className={styles.catalogListPage__container}>
        <h1>{t('ServicesCatalog:ServicesCatalogTitle')}</h1>
        <p className={styles.catalogListPage__headSection}>{t('ServicesCatalog:HereYouCanFindServices')}</p>
        <div className={styles.catalogListPage__wrap}>
          <div className={styles.catalogListPage__filtersContainer}>
            <span className={styles.catalogListPage__filtersLabel}>{t('Catalog:FiltersTitle')}</span>
            <div className={styles.catalogListPage__filterContent}>
              <SearchFilter
                dataTestId='CatalogPage:SearchByName'
                placeholder={t('CatalogPage:SearchByName')}
                value={nameFilter}
                setValue={setNameFilter}
              />
              <FiltersContainer
                filterData={filterData}
                addNewFilter={addNewFilter}
                deleteFilter={deleteFilter}
                updateFilter={updateFilter}
                isInitialized={isInitialized}
              />
            </div>
          </div>
          <div className={styles.catalogListPage__content}>
            <Spinner show={isLoading} centered />
            {!hasActiveFilters ? (
              <Carousel
                itemPerSlide={ITEMS_PER_CAROUSEL_SLIDE}
                loading={isCarouselLoading}
                title={t('ServicesCatalog:CompilationTitle')}
                items={carouselItems}
                renderItem={(service, key) => (
                  <Link
                    className={styles.catalogListPage__cardLink}
                    key={key}
                    to={{
                      pathname: `/${CATALOG_BASE_PATH}/${service.modelAccountName || service.id.accountId}/${
                        service.modelName || service.id.modelId
                      }`,
                      state: { from },
                    }}
                  >
                    <CardItem
                      service={service}
                      toggleFavorite={async () =>
                        setFavorite(service.id.accountId, service.id.modelId, !service.favorite)
                      }
                    />
                  </Link>
                )}
              />
            ) : null}
            <CardsList items={currentItems} setFavorite={setFavorite} />
          </div>
          {pageCount !== undefined && pageCount > 1 && (
            <Paginate
              nextLabel={t('ServicesCatalog:Paginate:NextLabel')}
              previousLabel={t('ServicesCatalog:Paginate:PreviousLabel')}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              pageRangeDisplayed={PAGE_RANGE_TO_DISPLAY}
              pageCount={pageCount}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(CatalogListPage);
